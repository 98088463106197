const crypto = require("crypto");

// 导出函数sha256，用于生成SHA-256哈希值
export function sha256(message, secret = "", encoding) {
  // 使用crypto模块中的createHmac函数，创建HMAC-SHA-256实例
  const hmac = crypto.createHmac("sha256", secret)
  // 返回生成的哈希值的编码
  return hmac.update(message).digest(encoding)
}

// 导出函数getHash，用于生成SHA-256哈希值
export function getHash(message, encoding = "hex") {
  // 使用crypto模块中的createHash函数，创建SHA-256实例
  const hash = crypto.createHash("sha256")
  // 返回生成的哈希值的编码
  return hash.update(message).digest(encoding)
}

// 导出函数getDate，用于根据时间戳生成日期
export function getDate(timestamp) {
  // 将时间戳转换为Date对象
  const date = new Date(timestamp * 1000)
  // 获取UTC年份
  const year = date.getUTCFullYear()
  // 获取UTC月份，并格式化为两位数
  const month = ("0" + (date.getUTCMonth() + 1)).slice(-2)
  // 获取UTC日期，并格式化为两位数
  const day = ("0" + date.getUTCDate()).slice(-2)
  // 返回日期字符串
  return `${year}-${month}-${day}`
}


export function uint8ArrayToString(fileData) {
  let dataString = '';
  for (let i = 0; i < fileData.length; i++) {
    dataString += String.fromCharCode(fileData[i]);
  }
  return dataString;
}

export function toUint8Array(wordArray) {
  // Shortcuts
  const words = wordArray.words;
  const sigBytes = wordArray.sigBytes;

  // Convert
  const u8 = new Uint8Array(sigBytes);
  for (let i = 0; i < sigBytes; i++) {
    u8[i] = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff;
  }
  return u8;
}

export function popupAnimate(from, to, cb) {
  let f = from
  setTimeout(() => {
    if (f > to) {
      f -= 2
      cb(f)
      if (f <= to) {
        f = to
        cb(f, true)
      } else {
        popupAnimate(f, to, cb)
      }
    } else {
      f += 2
      cb(f)
      if (f >= to) {
        cb(f, true)
        f = to
      } else {
        popupAnimate(f, to, cb)
      }
    }
  }, 15)
}

export function getCursortPosition(el) {
  let cursorIndex = 0
  if (document.selection) {
    // IE Support
    el.focus()
    const range = document.selection.createRange()
    range.moveStart('character', -el.value.length)
    cursorIndex = range.text.length
  } else if (el.selectionStart || el.selectionStart === 0) {
    // another support
    cursorIndex = el.selectionStart
  }
  return cursorIndex
}

/**
 * 获取url参数
 * @param {String} name 键
 */
export function getQueryString(name) {
  let reg = `(^|&)${name}=([^&]*)(&|$)`
  let r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}

const cacheScript = {}
// 加载脚本
export function loadScript(url) {
  // 如果已经加载过相同脚本，则不再加载
  if (cacheScript[encodeURIComponent(url)]) return cacheScript[encodeURIComponent(url)]

  // 新加载脚本缓存中记录
  cacheScript[encodeURIComponent(url)] = new Promise((resolve, reject) => {
    const oDom = document.createElement('script')
    oDom.onload = function () {
      resolve()
    }

    oDom.onerror = function () {
      reject(new Error())
    }
    oDom.src = url
    document.querySelector('head').appendChild(oDom)
  })

  return cacheScript[encodeURIComponent(url)]
}

// 指定长度和基数
export function generateuuid() {

  var s = [];

  var hexDigits = "0123456789abcdef";

  for (var i = 0; i < 32; i++) {

    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);

  }

  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010

  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01

  s[8] = s[13] = s[18] = s[23];

  var uuid = s.join("");

  return uuid;

}


