import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'
import multiguard from 'vue-router-multiguard'
import middlewares from "@/x/middlewares"
import routes from "./routes"
import Rebot from '../../rebot/pages/index.vue'
import V2 from '../../rebot/pages/index_v2.vue'
const defaultRoutes = [
  {
    path: '/',
    redirect: '/rebot'
  },
  {
    path: '/rebot',
    name: 'rebot',
    meta: {
      title: "机器人"
    },
    component: Rebot
  },
  {
    path: '/rebot/v2',
    name: 'rebotV2',
    meta: {
      title: "机器人"
    },
    // component: () => import("@rebot/pages/index_v2")
    component: V2
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: "登录"
    },
    component: () => import("@rebot/pages/login")
  },
]

console.log(defaultRoutes)
const router = createRouter({
  // history: createWebHistory('/technology-project-management-admin'),
  // history: createWebHistory('/'),
  history: createWebHashHistory(),
  routes:[...defaultRoutes, ...routes]
})
router.beforeEach(multiguard([...middlewares]))

export default router
