import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "flex flex-col gap-2 mt-[20px]"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  class: "truncate"
};
const _hoisted_4 = {
  class: "flex items-center"
};
const _hoisted_5 = {
  class: "flex items-center"
};
const _hoisted_6 = {
  class: "dialog-footer"
};
import { computed, ref } from "vue";
import store from "@/x/store";
import { ElMessage, ElMessageBox } from "element-plus";
import apis from "@/x/server";
export default {
  __name: 'SideBarContent',
  props: {
    showCreate: {
      type: Boolean,
      default: true
    }
  },
  emits: ['select'],
  setup(__props, {
    emit: __emit
  }) {
    const emits = __emit;
    const currentConversation = computed(() => store.state.$rebot.currentConversation);
    function selectChat(item) {
      store.commit("$rebot/setCurrentConversation", item);
      store.dispatch('$rebot/getHistoryMessages');
      emits('select');
    }
    const conversationList = computed(() => store.state.$rebot.conversationList);
    function startNewChat() {
      store.dispatch('$rebot/startNewChat');
      emits('select');
    }
    const conversationName = ref("");
    const conversationId = ref("");
    const dialogFormVisible = ref(false);
    function handleCommand(action, {
      id,
      name
    }) {
      switch (action) {
        case "edit":
          conversationName.value = name;
          conversationId.value = id;
          dialogFormVisible.value = true;
          break;
        case "delete":
          conversationId.value = id;
          deleteconversation();
          break;
      }
    }
    async function confirmEditconversationName() {
      if (!conversationName.value) {
        return ElMessage.error('会话名称必填!');
      }
      await apis.rebot.renameConversation(conversationId.value, {
        name: conversationName.value,
        user: store.state.$rebot.userInfo.userId
      });
      dialogFormVisible.value = false;
      store.dispatch('$rebot/getConversations');
    }
    function deleteconversation() {
      ElMessageBox.confirm('您确定要删除此对话吗？', '删除对话', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await apis.rebot.deleteConversation(conversationId.value, {
          user: store.state.$rebot.userInfo.userId
        });
        store.dispatch('$rebot/getConversations');
      }).catch(() => {});
    }
    return (_ctx, _cache) => {
      const _component_Edit = _resolveComponent("Edit");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_ChatDotRound = _resolveComponent("ChatDotRound");
      const _component_MoreFilled = _resolveComponent("MoreFilled");
      const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
      const _component_Delete = _resolveComponent("Delete");
      const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
      const _component_el_dropdown = _resolveComponent("el-dropdown");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createElementBlock(_Fragment, null, [__props.showCreate ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "shadow border h-[36px] rounded-lg flex items-center gap-2 text-[16px] pl-[14px] active:opacity-80 mt-3 text-primary-600 cursor-pointer font-bold",
        onClick: startNewChat
      }, [_createVNode(_component_el_icon, null, {
        default: _withCtx(() => [_createVNode(_component_Edit)]),
        _: 1
      }), _cache[4] || (_cache[4] = _createElementVNode("span", {
        class: "text-[14px]"
      }, "新对话", -1))])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(conversationList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["h-[40px] flex items-center gap-2 cursor-pointer hover:bg-[#f3f3f3] pr-[10px] group text-[14px] bg-[#fafafa] rounded-md pl-[14px] font-bold", {
            '!bg-primary-50 text-primary-600': item.id === currentConversation.value?.id
          }]),
          key: index,
          onClick: $event => selectChat(item)
        }, [_createVNode(_component_el_icon, {
          size: "16"
        }, {
          default: _withCtx(() => [_createVNode(_component_ChatDotRound)]),
          _: 1
        }), _createElementVNode("span", _hoisted_3, _toDisplayString(item.name), 1), _createVNode(_component_el_dropdown, {
          class: "ml-auto group",
          trigger: "click",
          onCommand: action => handleCommand(action, item),
          placement: "bottom-end"
        }, {
          dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
            default: _withCtx(() => [_createVNode(_component_el_dropdown_item, {
              command: "edit"
            }, {
              default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_icon, null, {
                default: _withCtx(() => [_createVNode(_component_Edit)]),
                _: 1
              }), _cache[5] || (_cache[5] = _createTextVNode("重命名"))])]),
              _: 1
            }), _createVNode(_component_el_dropdown_item, {
              command: "delete",
              divided: ""
            }, {
              default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_icon, null, {
                default: _withCtx(() => [_createVNode(_component_Delete)]),
                _: 1
              }), _cache[6] || (_cache[6] = _createTextVNode("删除"))])]),
              _: 1
            })]),
            _: 1
          })]),
          default: _withCtx(() => [_createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
            class: "opacity-100 tablet:opacity-0 group-hover:opacity-100 w-[20px] h-[20px] bg-[#eee] flex items-center justify-center rounded-sm"
          }, [_createVNode(_component_el_icon, null, {
            default: _withCtx(() => [_createVNode(_component_MoreFilled)]),
            _: 1
          })])]),
          _: 2
        }, 1032, ["onCommand"])], 10, _hoisted_2);
      }), 128))]), _createVNode(_component_el_dialog, {
        modelValue: dialogFormVisible.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => dialogFormVisible.value = $event),
        title: "重命名会话",
        width: "90vw",
        class: "my-dialog !mt-[30vh] !max-w-[500px]",
        "close-on-click-modal": false
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_6, [_createVNode(_component_el_button, {
          onClick: _cache[2] || (_cache[2] = $event => dialogFormVisible.value = false)
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("取消")])),
          _: 1
        }), _createVNode(_component_el_button, {
          class: "!bg-[var(--el-color-primary)]",
          type: "primary",
          onClick: confirmEditconversationName
        }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("保存")])),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          "label-position": "top",
          size: "large"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "会话名称"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: conversationName.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => conversationName.value = $event),
              placeholder: "请输入会话名称",
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"])], 64);
    };
  }
};