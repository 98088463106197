

import "@/x/tools/cryptojs.js"
import { showLoadingToast } from 'vant';
import eventBus from "@/x/tools/eventBus";
import WebRecorder from "@/libs/asr/webrecorder"
import SpeechRecognizer from "@/libs/asr/alispeechrecognizer"
import { ref } from "vue";

export default function useAsr({ waveView = null, audioCallback = null, showLoading = true }) {
  let isCanStop;
	let isCanSendData = false;
  let recorder;
  let speechRecognizer;
	let resultText = '';
  const RECORD_STATE = {
    PENDING: 'pending',
    RECORDING: 'recording',
    RECOGNIZING: 'recognizing',
		RECORDED: 'recorded'
  }
  const ASRRecognizedState = ref(RECORD_STATE.PENDING)
  const ASRRecognizedText = ref("")

  function start() {
		resultText = ''
		ASRRecognizedState.value = RECORD_STATE.PENDING
		ASRRecognizedText.value = ''
    startRecord()
  }

  function startRecord() {
		let connectingToast = null;
		isCanSendData = false;
		if(showLoading) {
			connectingToast = showLoadingToast({
				message: '建立连接中...',
				forbidClick: true,
				position: "bottom"
			});
		}
		recorder = new WebRecorder();
		recorder.OnReceivedData = (audioData, waveData) => {
			// wave view
			if(waveView && waveView.value && isCanStop) {
				waveView.value.input(waveData, 100, 44100)
			}
			if(audioCallback) {
				audioCallback(audioData)
			}
      // 发送数据
			if(speechRecognizer && isCanSendData) speechRecognizer.write(audioData);
		};
		// 录音失败时
		recorder.OnError = (e) => {
			console.error("recorder error", e)
			recorder.stop();
		}
		recorder.start();
		if (!speechRecognizer) {
			speechRecognizer = new SpeechRecognizer();
		}
		// 开始识别
		speechRecognizer.OnRecognitionStart = (res) => {
			console.log('开始识别', res);
			connectingToast?.close()
			isCanStop = true;
			isCanSendData = true;
      ASRRecognizedState.value = RECORD_STATE.RECORDING
		};
		// 识别变化时
		speechRecognizer.OnRecognitionResultChange = (result) => {
			console.error("OnRecognitionResultChange", result);
			const currentText = `${resultText}${result}`;
      ASRRecognizedText.value = currentText;
		};
		// 一段话结束
		speechRecognizer.OnSentenceEnd = (result) => {
			console.error("OnSentenceEnd", result);
			resultText += result;
			ASRRecognizedText.value = resultText;
		}
		// 识别结束
		speechRecognizer.OnRecognitionComplete = (res) => {
			console.log('识别结束', res);
			isCanSendData = false;
			eventBus.emit('end-asr');
		};
		// 识别错误
		speechRecognizer.OnError = (res) => {
			console.log('识别失败', res);
			isCanSendData = false;
			stop()
			eventBus.emit('end-asr');
		};
		// 建立连接
		speechRecognizer.start();
	}

  function stop() {
    if (recorder) {
      recorder.stop();
    }
		if (isCanStop) {
			speechRecognizer.stop();
		}
		isCanStop = false
		// 停止时放入空数据 重置波形
		if(waveView && waveView.value) {
			waveView.value.input(new Int16Array(1440), 100, 44100)
		}
		if(audioCallback) {
			audioCallback()
		}
  }

  return {
    start,
    stop,
    ASRRecognizedText,
		ASRRecognizedState
  }
}