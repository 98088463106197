import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "flex-1 min-w-[300px] px-[20px] py-[15px] bg-white shadow rounded-lg"
};
const _hoisted_2 = {
  class: "flex flex-col gap-[5px] text-[14px] mt-[14px] cursor-pointer"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "w-[20px] h-[20px] rounded-md bg-gradient-to-br from-[#D1EAFF] to-[#68ABFF] flex items-center justify-center text-white"
};
const _hoisted_5 = {
  class: "flex-1"
};
import store from "@/x/store";
import { computed } from "vue";
export default {
  __name: 'Recommend',
  emits: ['send'],
  setup(__props, {
    emit: __emit
  }) {
    const emits = __emit;
    const globalParameters = computed(() => store.state.$rebot.globalParameters);
    const suggested_questions = computed(() => globalParameters.value?.suggested_questions || []);
    return (_ctx, _cache) => {
      return suggested_questions.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "font-bold text-[18px]"
      }, "热门问题", -1)), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(suggested_questions.value, (question, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "flex flex-start gap-[10px] mt-[5px]",
          key: index,
          onClick: $event => emits('send', question)
        }, [_createElementVNode("div", _hoisted_4, _toDisplayString(index + 1), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(question), 1)], 8, _hoisted_3);
      }), 128))])])) : _createCommentVNode("", true);
    };
  }
};