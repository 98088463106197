import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue";
import _imports_0 from '@/assets/rebot/showDataTree.png';
const _hoisted_1 = {
  class: "flex items-center justify-between h-full px-[15px] text-[20px] border-b"
};
const _hoisted_2 = {
  class: "flex-1 relative h-full"
};
import { onBeforeMount, ref, computed, nextTick } from "vue";
import store from "@/x/store";
import { useRoute } from "vue-router";
import { v4 as uuidv4 } from "uuid";
export default {
  __name: 'index_v2',
  setup(__props) {
    const route = useRoute();
    const coachConfig = computed(() => store.state.$rebot.coachConfig);
    const showSideBar = ref(false);
    function handlerShowSideBar() {
      showSideBar.value = true;
    }
    const showRightSideBar = ref(false);
    function handleShowRightSideBar() {
      showRightSideBar.value = true;
    }
    const main = ref(null);
    function reEditMessage(msg) {
      nextTick(() => {
        main.value?.reEditMessage(msg);
      });
    }
    onBeforeMount(async () => {
      store.commit('$rebot/setRebotVersion', 2);
      const {
        appId = '2'
      } = route.query;
      store.commit("$rebot/setAppId", appId);
      await store.dispatch('$rebot/getCoachConfig', {
        appId
      });
      if (!store.state.$rebot.userInfo.userId) {
        store.commit('$rebot/setUserInfo', {
          userId: uuidv4()
        });
      }
      // if(coachConfig.value.appName) {
      //   document.title = coachConfig.appName
      // }
      initPage();
    });
    const currentConversation = computed(() => store.state.$rebot.currentConversation);
    function initPage() {
      store.dispatch("$rebot/getConversations");
      store.dispatch("$rebot/getParameters");
      if (!currentConversation.value?.id) {
        store.commit('$rebot/setCurrentConversation', null);
      } else {
        store.dispatch('$rebot/getHistoryMessages');
      }
    }
    return (_ctx, _cache) => {
      const _component_Operation = _resolveComponent("Operation");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_header = _resolveComponent("el-header");
      const _component_SideBarContent = _resolveComponent("SideBarContent");
      const _component_el_aside = _resolveComponent("el-aside");
      const _component_MainContent = _resolveComponent("MainContent");
      const _component_el_main = _resolveComponent("el-main");
      const _component_RightSideBarContent = _resolveComponent("RightSideBarContent");
      const _component_el_container = _resolveComponent("el-container");
      const _component_el_drawer = _resolveComponent("el-drawer");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_container, {
        class: "!h-[100%] !min-w-[300px]"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_header, {
          class: "pc:hidden !h-[50px] !p-0"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_icon, {
            onClick: handlerShowSideBar
          }, {
            default: _withCtx(() => [_createVNode(_component_Operation)]),
            _: 1
          }), coachConfig.value.showDataTree === 1 ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _imports_0,
            onClick: handleShowRightSideBar,
            class: "w-[30px] h-[30px]",
            alt: ""
          })) : _createCommentVNode("", true)])]),
          _: 1
        }), _createVNode(_component_el_container, {
          class: "pc:h-[100%] h-[calc(100%-50px)]"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_aside, {
            class: "border-r hidden pc:block h-[100%] px-4 customScrollbar",
            width: "240px"
          }, {
            default: _withCtx(() => [_createVNode(_component_SideBarContent, {
              onSelect: _cache[0] || (_cache[0] = $event => showSideBar.value = false)
            })]),
            _: 1
          }), _createVNode(_component_el_main, {
            class: "!p-0 !flex !flex-col !flex-1 bg-[#F3F7FF]"
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_MainContent, {
              ref_key: "main",
              ref: main
            }, null, 512)])]),
            _: 1
          }), coachConfig.value.showDataTree === 1 ? (_openBlock(), _createBlock(_component_el_aside, {
            key: 0,
            class: "border-l hidden pc:block h-[100%] px-4",
            width: "280px"
          }, {
            default: _withCtx(() => [_createVNode(_component_RightSideBarContent, {
              onSelect: _cache[1] || (_cache[1] = $event => showRightSideBar.value = false),
              onReEdit: reEditMessage
            })]),
            _: 1
          })) : _createCommentVNode("", true)]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_drawer, {
        modelValue: showSideBar.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => showSideBar.value = $event),
        direction: "ltr",
        class: "!w-[240px] my-drawer",
        withHeader: false
      }, {
        default: _withCtx(() => [_createVNode(_component_SideBarContent, {
          onSelect: _cache[2] || (_cache[2] = $event => showSideBar.value = false)
        })]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_el_drawer, {
        modelValue: showRightSideBar.value,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => showRightSideBar.value = $event),
        direction: "rtl",
        class: "!w-[280px] my-drawer",
        withHeader: false
      }, {
        default: _withCtx(() => [_createVNode(_component_RightSideBarContent, {
          onSelect: _cache[4] || (_cache[4] = $event => showRightSideBar.value = false),
          onReEdit: reEditMessage
        })]),
        _: 1
      }, 8, ["modelValue"])], 64);
    };
  }
};