import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, unref as _unref, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
import _imports_0 from '@/assets/rebot/stop2.png';
import _imports_1 from '@/assets/rebot/recorder.png';
import _imports_2 from '@/assets/rebot/stop.png';
import _imports_3 from '@/assets/rebot/send.png';
const _hoisted_1 = {
  class: "relative max-w-[1000px] m-auto min-h-[92px] max-h-[140px] rounded-xl bg-white border w-full"
};
const _hoisted_2 = {
  class: "absolute h-[fit-content] bg-white w-full top-[-2px] translate-y-[-100%] rounded-xl p-[15px]"
};
const _hoisted_3 = {
  class: "text-[12px] flex flex-col gap-2 mt-2 cursor-pointer"
};
const _hoisted_4 = ["onClick", "innerHTML"];
const _hoisted_5 = {
  class: "absolute right-[15px] bottom-[10px] w-[fit-content] z-[10] flex gap-2"
};
const _hoisted_6 = {
  key: 2,
  class: "flex items-center justify-center h-[92px] w-full backdrop-blur-xl rounded-2xl overflow-hidden"
};
import apis from "@/x/server";
import store from "@/x/store";
import { computed, ref, inject, watch, nextTick } from "vue";
import { debounce } from "lodash";
import eventBus from "@/x/tools/eventBus";
import useAliAsr from "@/x/hooks/useAliAsr";
export default {
  __name: 'UserInput',
  emits: ["login", "send", "stop"],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const isGenerating = inject("isGenerating");
    // import useAsr from "@/x/hooks/useAsr";
    const message = ref("");
    const inputFocus = ref(false);
    const needLogin = computed(() => !!store.state.$rebot.coachConfig.needLogin);
    const isLogin = computed(() => !!store.state.$rebot.userInfo.token);
    const version = computed(() => store.state.$rebot.rebotVersion);
    const emits = __emit;
    const inputStatus = ref("TEXT");
    function userLogin() {
      emits("login");
    }
    function stopGenerate() {
      emits("stop");
    }
    function sendMessage() {
      if (!message.value.trim()) return;
      emits("send", message.value);
      message.value = "";
      rebotQuestions.value = [];
    }
    const asr = useAliAsr({});
    const currentEvent = ref(null);
    const handleKeyDown = event => {
      currentEvent.value = event.target;
      // 定义组合键 Map
      const shortCutKeys = ['metaKey', 'altKey', 'ctrlKey', 'shiftKey'];
      const isEnterKey = event.key === 'Enter';
      const isShortcutKeys = shortCutKeys.some(item => event[item]);
      if (isEnterKey && isShortcutKeys) {
        if (event.shiftKey) event.preventDefault();
        // 获取光标位置
        const cursorPosition = currentEvent.value.selectionStart;
        // 拆分成两段文本
        const textBeforeCursor = message.value.slice(0, cursorPosition);
        const textAfterCursor = message.value.slice(cursorPosition);
        // 合并为带有换行符的新文本
        const newText = textBeforeCursor + '\n' + textAfterCursor;
        // 更新输入框的值
        message.value = newText;
        // 文本编辑器的高度发生变化后
        nextTick(() => {
          // 高度变化 自动滚动到底部
          const editor = document.querySelector(".userInput textarea");
          editor.scrollTop = editor.scrollHeight;
          // 设置光标位置为: start 和 end 相同，光标会移动到换行符后面的新行首
          currentEvent.value.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
        });
      } else if (event.key === 'Enter') {
        // 阻止掉 Enter 的默认换行行为
        event.preventDefault();
        // do yourself things for example  send
        sendMessage();
      }
    };
    watch(asr.ASRRecognizedText, newVal => {
      console.error("message new val:", newVal);
      message.value = newVal;
    });
    const status = ref("NOT_START");
    function startSpeak() {
      inputStatus.value = "VOICE";
      asr.start();
      status.value = "READY";
      eventBus.off("end-asr");
      eventBus.on("end-asr", () => {
        status.value = "NOT_START";
        inputStatus.value = "TEXT";
      });
    }
    function stopSpeak() {
      asr.stop();
      eventBus.off("end-asr");
      eventBus.on("end-asr", () => {
        status.value = "NOT_START";
        inputStatus.value = "TEXT";
      });
    }
    __expose({
      setMessage: msg => message.value = msg
    });
    const rebotQuestions = ref([]);
    const highLightRebotQuestions = computed(() => {
      return rebotQuestions.value.map(item => {
        return {
          ...item,
          highLightQuestions: item.questions.replace(`${message.value}`, `<span class='text-[#4178FF]'>${message.value}</span>`)
        };
      });
    });
    async function userInput() {
      debounceUserInput();
    }
    const debounceUserInput = debounce(getRebotQuestions, 500);
    async function getRebotQuestions() {
      if (!message.value) {
        rebotQuestions.value = [];
        return;
      }
      const {
        code,
        data
      } = await apis.rebot.getRebotQuestions({
        query: message.value
      });
      if (code === 200) {
        rebotQuestions.value = data;
      }
    }
    function userInputFocus() {
      inputFocus.value = true;
      if (!message.value) {
        rebotQuestions.value = [];
      }
    }
    function userInputBlur() {
      setTimeout(() => {
        inputFocus.value = false;
      }, 200);
    }
    function questionClick(question) {
      console.error("question click:", question);
      message.value = question;
      rebotQuestions.value = [];
    }
    return (_ctx, _cache) => {
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_button = _resolveComponent("van-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createElementVNode("div", _hoisted_2, [_cache[2] || (_cache[2] = _createElementVNode("span", {
        class: "text-[14px] font-bold"
      }, "问题提示", -1)), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(highLightRebotQuestions.value, (ques, index) => {
        return _openBlock(), _createElementBlock("span", {
          onClick: $event => questionClick(ques.questions),
          key: index,
          innerHTML: ques.highLightQuestions
        }, null, 8, _hoisted_4);
      }), 128))])], 512), [[_vShow, rebotQuestions.value.length && inputFocus.value && message.value]]), _unref(isGenerating) ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        onClick: stopGenerate,
        class: "absolute m-auto top-[-6px] left-[50%] translate-x-[-50%] translate-y-[-100%] bg-white w-[95px] h-[30px] rounded-full border flex items-center justify-center cursor-pointer"
      }, _cache[3] || (_cache[3] = [_createElementVNode("img", {
        class: "w-[16px] h-[16px] mr-1",
        src: _imports_0,
        alt: ""
      }, null, -1), _createElementVNode("span", null, "停止生成", -1)]))) : _createCommentVNode("", true), !needLogin.value || isLogin.value ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_createVNode(_component_van_field, {
        modelValue: message.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => message.value = $event),
        autosize: {
          minHeight: 92,
          maxHeight: 92
        },
        type: "textarea",
        onKeydown: _cache[1] || (_cache[1] = $event => handleKeyDown($event)),
        onInput: userInput,
        onFocus: userInputFocus,
        onBlur: userInputBlur,
        class: "userInput my-input !p-0 !pl-[15px] rounded-2xl",
        placeholder: inputStatus.value === 'TEXT' ? '“输入你的问题，点击右下角“发送”按钮，进行发送~' : '请说话'
      }, null, 8, ["modelValue", "placeholder"]), _createElementVNode("div", _hoisted_5, [status.value === 'NOT_START' ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([[version.value === 1 ? 'bg-gradient-to-b from-[#E3A2FF] to-[#4E82FF]' : '!bg-[#4E82FF]'], "cursor-pointer flex items-center justify-center w-[30px] h-[30px] rounded-full"]),
        onClick: startSpeak
      }, _cache[4] || (_cache[4] = [_createElementVNode("img", {
        class: "w-[18px] h-[18px]",
        src: _imports_1,
        alt: ""
      }, null, -1)]), 2)) : _createCommentVNode("", true), status.value === 'READY' ? (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass([[version.value === 1 ? 'bg-gradient-to-b from-[#E3A2FF] to-[#4E82FF]' : '!bg-[#4E82FF]'], "cursor-pointer flex items-center justify-center text-white gap-1 w-[95px] h-[30px] rounded-full"]),
        onClick: stopSpeak
      }, _cache[5] || (_cache[5] = [_createElementVNode("img", {
        class: "w-[18px] h-[18px]",
        src: _imports_2,
        alt: ""
      }, null, -1), _createTextVNode("停止录音 ")]), 2)) : _createCommentVNode("", true), inputStatus.value === 'TEXT' ? (_openBlock(), _createElementBlock("div", {
        key: 2,
        onClick: sendMessage,
        class: _normalizeClass([[version.value === 1 ? 'bg-gradient-to-b from-[#E3A2FF] to-[#4E82FF]' : '!bg-[#4E82FF]', {
          'opacity-60': !message.value
        }], "text-white font-bold text-[14px] gap-[2px] flex items-center justify-center cursor-pointer w-[70px] h-[30px] rounded-full"])
      }, _cache[6] || (_cache[6] = [_createElementVNode("img", {
        class: "w-[18px] h-[18px]",
        src: _imports_3,
        alt: ""
      }, null, -1), _createTextVNode(" 发送 ")]), 2)) : _createCommentVNode("", true)])], 64)) : (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_van_button, {
        type: "primary",
        class: "!rounded-xl min-w-[120px] !bg-[var(--van-primary-color)]",
        onClick: userLogin
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("请先登录")])),
        _: 1
      })]))]);
    };
  }
};