import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, unref as _unref, normalizeClass as _normalizeClass } from "vue";
import _imports_0 from '@/assets/rebot/ai_avatar.png';
import _imports_1 from '@/assets/rebot/loading.png';
import _imports_2 from '@/assets/rebot/fuzhi.png';
import _imports_3 from '@/assets/rebot/dianzan_active.png';
import _imports_4 from '@/assets/rebot/dianzan.png';
import _imports_5 from '@/assets/rebot/diancai_active.png';
import _imports_6 from '@/assets/rebot/diancai.png';
import _imports_7 from '@/assets/rebot/reedit_light.png';
const _hoisted_1 = {
  key: 0,
  class: "left-chat-content"
};
const _hoisted_2 = {
  class: "chat !whitespace-pre-wrap"
};
const _hoisted_3 = {
  class: "flex flex-wrap gap-2 mt-2"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "w-[36px] h-[36px] rounded-full overflow-hidden"
};
const _hoisted_6 = {
  key: 0,
  class: "w-full h-full flex items-center justify-center text-[14px]"
};
const _hoisted_7 = ["src"];
const _hoisted_8 = {
  key: 0,
  class: "flex flex-col flex-1 max-w-[100%]"
};
const _hoisted_9 = {
  class: "chat relative !leading-[24px] tablet:!leading-[28px] group"
};
const _hoisted_10 = {
  key: 0,
  class: "min-w-[200px]"
};
const _hoisted_11 = {
  key: 1,
  class: "bg-[#F3F4FC] rounded-md flex flex-wrap gap-1 px-2 py-1"
};
const _hoisted_12 = {
  key: 0,
  class: "w-[12px] h-[12px] mr-1 animate-rotating",
  src: _imports_1,
  alt: ""
};
const _hoisted_13 = {
  class: "font-bold"
};
const _hoisted_14 = ["innerHTML"];
const _hoisted_15 = ["onClick"];
const _hoisted_16 = {
  class: "h-[25px] w-[60px] px-[5px] shadow bg-white rounded-md cursor-pointer flex justify-around items-center"
};
const _hoisted_17 = ["onClick"];
const _hoisted_18 = ["onClick"];
const _hoisted_19 = ["onClick"];
const _hoisted_20 = {
  class: "relative pt-[5px]"
};
const _hoisted_21 = {
  class: "grid grid-cols-2 gap-2 mt-[10px]"
};
const _hoisted_22 = {
  class: "mt-[25px] flex"
};
const _hoisted_23 = ["onClick"];
const _hoisted_24 = {
  key: 3,
  class: "border-t mt-2 pt-2"
};
const _hoisted_25 = {
  class: "flex gap-2 flex-wrap"
};
const _hoisted_26 = ["onClick"];
const _hoisted_27 = {
  class: "p-2 max-h-[400px] overflow-y-auto customScrollbar"
};
const _hoisted_28 = {
  class: "p-1 bg-[#E9E9E9] text-[#585858] w-[fit-content] rounded-md"
};
const _hoisted_29 = {
  class: "text-[14px] mt-2 whitespace-pre-wrap"
};
const _hoisted_30 = {
  class: "flex items-center bg-[#F3F4FC] py-[1px] pl-[6px] pr-[8px] rounded-md cursor-pointer text-[12px]"
};
const _hoisted_31 = {
  class: "pl-[10px] pt-2 flex flex-col gap-1 text-[14px]"
};
const _hoisted_32 = ["onClick"];
const _hoisted_33 = {
  key: 1,
  class: "chat"
};
const _hoisted_34 = {
  class: "flex flex-col"
};
const _hoisted_35 = {
  class: "border-b-[0.5px] px-[10px] py-[8px] text-right"
};
const _hoisted_36 = ["onClick"];
import base64 from "@/x/tools/base64";
// import DotFlashing from "@/components/DotFlashing/index.vue"
import { ref, computed, nextTick, watch, onMounted, inject } from "vue";
import store from "@/x/store";
import { ElMessage } from 'element-plus';
import { showImagePreview } from 'vant';
import "highlight.js/styles/a11y-dark.css";
import apis from "@/x/server";
import copy from 'copy-to-clipboard';
import { v4 as uuidv4 } from "uuid";
import * as echarts from 'echarts';
export default {
  __name: 'ChatContent',
  emits: ['reEdit'],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const hljs = require('highlight.js');
    const coachConfig = computed(() => store.state.$rebot.coachConfig);
    const coachURL = computed(() => store.state.$rebot.coachConfig.coachURL);
    const phoneNum = computed(() => store.state.$rebot.userInfo.phoneNum);
    const token = computed(() => store.state.$rebot.userInfo.token);
    const isGenerating = inject('isGenerating');
    onMounted(() => {
      scrollBottom(true);
    });
    console.error(base64.encode('编号22_国能朔黄铁路发展有限责任公司科技工作管理规定'));
    const md = require('markdown-it')({
      html: true,
      linkify: true,
      typographer: true,
      highlight: function (str, lang) {
        if (lang === 'echarts') {
          const chartId = uuidv4();
          nextTick(() => {
            const chartDom = document.getElementById(chartId);
            const myChart = echarts.init(chartDom);
            // 指定图表的配置项和数据
            let option = JSON.parse(str);
            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);
            const {
              width
            } = scrollRef.value.getBoundingClientRect();
            // my-charts
            const myChartsDoms = document.getElementsByClassName('my-charts');
            [...myChartsDoms].forEach(dom => {
              dom.style.width = `${width * 0.78}px`;
              myChart.resize();
            });
            myChart.resize({
              width: width * 0.78
            });
          });
          return `<div id="${chartId}" class="my-charts" style="width: 200px; height: 400px;"></div>`;
        }
        if (lang && hljs.getLanguage(lang)) {
          try {
            return `<pre class="hljs whitespace-pre-line p-[10px]"><code class='language-${lang}'>${hljs.highlight(str, {
              language: lang
            }).value}</code></pre>`;
          } catch (__) {}
        }
        // hljs 
        return '<pre class="whitespace-pre-line p-[10px]"><code>' + md.utils.escapeHtml(str) + '</code></pre>'; // use external default escaping
      }
    });
    function toPdfDetail({
      document_id
    }) {
      // const name = base64.encode(document_name.split(".")[0])
      // const pdfUrl = `${window.origin}/pdfs/${name}.pdf`
      // const url = `${window.origin}/pdfjs/web/viewer2.html?file=${encodeURIComponent(pdfUrl)}`
      // window.open(url, "_blank")
      getDocuments(document_id);
    }
    async function getDocuments(document_id) {
      const {
        data
      } = await apis.coach.getDocuments({
        difydocids: [document_id]
      });
      if (data[document_id] && data[document_id].PDF_RELATIVE_PATH) {
        const pdfUrl = `/doc/${data[document_id].PDF_RELATIVE_PATH}`;
        const url = `${window.origin}/pdfjs/web/viewer2.html?file=${encodeURIComponent(pdfUrl)}`;
        window.open(url, "_blank");
      }
    }
    const globalParameters = computed(() => store.state.$rebot.globalParameters);
    const appId = computed(() => store.state.$rebot.appId);
    const userId = computed(() => store.state.$rebot.userInfo.userId);
    const currentConversation = computed(() => store.state.$rebot.currentConversation);
    const checkedFeedbackOptions = ref([]);
    async function submitFeedback(chat, index) {
      const data = {
        appId: appId.value,
        query: chats.value[index - 1]?.content,
        userId: userId.value,
        conversationId: currentConversation.value?.id,
        answer: chat.content,
        isLike: -1,
        reason: checkedFeedbackOptions.value.join(',')
      };
      await apis.rebot.putMessageFeedback(data);
      await messageFeedback(chat, 'dislike');
      chat.showFeedbackPopup = false;
    }
    function cancelFeedback(chat) {
      chat.showFeedbackPopup = false;
      messageFeedback(chat, 'dislike');
    }
    const feedbackOptions = computed(() => store.state.$rebot.feedbackOptions);
    function selectFeedbackOption({
      option_text
    }) {
      if (checkedFeedbackOptions.value.includes(option_text)) {
        checkedFeedbackOptions.value = checkedFeedbackOptions.value.filter(item => item !== option_text);
      } else {
        checkedFeedbackOptions.value.push(option_text);
      }
    }

    // const userAvatar = computed(() => require('@/assets/images/user.svg'))
    const userAvatar = computed(() => store.state.$rebot.coachConfig?.userAvatar || require('@/assets/images/user.svg'));
    const chats = ref([]);
    const scrollRef = ref(null);
    const canScrollBottom = ref(true);
    function handleScroll() {
      const container = scrollRef.value;
      if (container && container.scrollHeight - (container.scrollTop + container.clientHeight) > 20) {
        canScrollBottom.value = false;
      } else {
        canScrollBottom.value = true;
      }
    }
    const scrollBottom = async (force = false) => {
      await nextTick();
      const container = scrollRef.value;
      if (container && (force || canScrollBottom.value)) {
        container.scrollTop = container.scrollHeight;
      }
    };
    const historyMessages = computed(() => store.state.$rebot.historyMessages);
    watch(() => historyMessages.value, newVal => {
      chats.value = handleHistoryMessages(newVal);
      scrollBottom();
    }, {
      immediate: true
    });
    function handleHistoryMessages(messages) {
      const temp = [];
      messages.forEach(message => {
        if (message.query) {
          temp.push({
            dir: 'right',
            content: message.query
          });
        }
        if (message.answer) {
          temp.push({
            dir: 'left',
            content: message.answer,
            id: message.id,
            feedback: message.feedback || {},
            showFeedbackPopup: false,
            feedbackReasons: [],
            resources: getGroupedTrieverResources(message.retriever_resources || [])
          });
        }
      });
      return temp;
    }
    function getGroupedTrieverResources(triever_resources) {
      const resources = [];
      console.error(triever_resources);
      triever_resources.forEach(resource => {
        console.error(resources.find(item => item.document_id));
        if (!resources.find(item => item.document_id === resource.document_id)) {
          resources.push({
            document_id: resource.document_id,
            document_name: resource.document_name,
            contents: [{
              segment_position: resource.segment_position,
              content: resource.content
            }]
          });
        } else {
          console.error("else:::", resource.document_id, resources.find(item => item.document_id === resource.document_id));
          resources.find(item => item.document_id === resource.document_id).contents.push({
            segment_position: resource.segment_position,
            content: resource.content
          });
        }
      });
      return resources;
    }
    async function imagePreview(e) {
      if (e.target.nodeName === 'IMG') {
        if (e.target.src) showImagePreview({
          images: [e.target.src],
          closeable: true
        });
      }
      if (e.target.nodeName === 'A' && coachURL.value) {
        e.preventDefault();
        const coachUrl = coachURL.value;
        if (e.target.href && e.target.href.includes(coachUrl) && phoneNum.value) {
          if (store.getters['$rebot/isTokenExpaired']) {
            await store.dispatch('$rebot/coachLogin', {
              phoneNum: phoneNum.value
            });
          }
          const url = e.target.href.includes("?") ? `${e.target.href}&token=${token.value}` : `${e.target.href}?token=${token.value}`;
          window.open(url, '_blank');
        } else {
          window.open(e.target.href, '_blank');
        }
      }
    }
    function reCreateAnswer() {
      const reverseChats = [...chats.value].reverse();
      const lastChat = reverseChats.find(item => item.dir === 'right');
      if (lastChat) {
        sendUserMessage(lastChat.content);
      }
    }
    function sendUserMessage(msg) {
      // 默认一个currentConversitionId
      if (isGenerating.value) {
        stopGenerating();
      }
      chats.value.push({
        content: msg,
        dir: 'right',
        loading: false
      });
      store.commit('$rebot/setNextSuggested', []);
      scrollBottom(true);
      sendRemote(msg);
    }
    const nextSuggested = computed(() => store.state.$rebot.nextSuggested || []);
    const emits = __emit;
    function reEdit(msg) {
      emits('reEdit', msg);
    }
    const chatItem = ref({});
    // const isBotEnded = ref(false) 
    let source = null;
    async function sendRemote(query = '') {
      isGenerating.value = true;
      chats.value.push({
        content: '',
        dir: 'left',
        loading: true
      });
      const conversation_id = currentConversation.value?.id === 'default' ? '' : currentConversation.value?.id;
      const data = {
        response_mode: 'streaming',
        inputs: {},
        query,
        user: userId.value,
        conversation_id,
        files: ''
      };
      // let messageId = ''
      source = await apis.rebot.sendMessage(data, {
        onOpen: () => {
          chats.value.pop();
          chatItem.value = {
            id: '',
            content: '',
            dir: 'left',
            loading: false,
            feedback: {},
            workflows: [],
            showFeedbackPopup: false,
            feedbackReasons: [],
            resources: []
          };
          chats.value.push(chatItem.value);
        },
        onMessage: responseData => {
          console.error("responseData", responseData);
          // messageId = responseData.message_id
          chatItem.value.id = responseData.message_id;
          if (responseData.answer) {
            chatItem.value.content += responseData.answer;
            scrollBottom();
          }
          if (coachConfig.value.showWorkflow) {
            if (responseData.event === 'node_started') {
              chatItem.value.workflows.push({
                node_id: responseData.data.node_id,
                node_title: responseData.data.title,
                status: 'running'
              });
            }
            if (responseData.event === 'node_finished') {
              chatItem.value.workflows.find(workflow => workflow.node_id === responseData.data.node_id).status = 'success';
            }
            if (responseData.event === 'node_failed') {
              chatItem.value.workflows.find(workflow => workflow.node_id === responseData.data.node_id).status = 'failed';
            }
          }
          if (responseData.event === 'message_end') {
            const retriever_resources = responseData.metadata?.retriever_resources || [];
            chatItem.value.resources = getGroupedTrieverResources(retriever_resources);
            scrollBottom();
          }
        },
        onEnd: async answerResult => {
          isGenerating.value = false;
          chatItem.value.content = answerResult;
          if (globalParameters.value.suggested_questions_after_answer?.enabled) {
            // getSuggested(messageId)
            getSuggested({
              query,
              answer: answerResult
            });
          }
          if (!currentConversation.value?.id || currentConversation.value.id === 'default') {
            await store.dispatch('$rebot/getConversations');
            if (store.state.$rebot.conversationList[0]) {
              store.commit('$rebot/setCurrentConversation', store.state.$rebot.conversationList[0]);
            }
          }
        }
      });
    }
    function stopGenerating() {
      if (isGenerating.value) {
        isGenerating.value = false;
        const lastChat = chats.value[chats.value.length - 1];
        console.error("lastChat::::", lastChat);
        // 在loading且没有content时, 显示已停止生成
        if (lastChat && !lastChat.content) {
          chats.value.pop();
          chats.value.push({
            dir: 'left',
            content: '已停止生成',
            loading: false
          });
        }
        source?.close();
      }
    }
    __expose({
      sendUserMessage,
      stopGenerating
    });
    async function getSuggested({
      query,
      answer
    }) {
      // const { result, data } = await apis.rebot.getSuggested(messageId, { user: store.state.$rebot.userInfo.userId })
      // if(result === 'success') {
      //   store.commit('$rebot/setNextSuggested', data)
      //   scrollBottom()
      // }
      const {
        code,
        data
      } = await apis.rebot.getRebotQuestions({
        query,
        answer: answer.slice(0, 500)
      });
      if (code === 200) {
        store.commit('$rebot/setNextSuggested', (data || []).slice(0, 3).map(item => item.questions));
        scrollBottom();
      }
    }
    function copyText(text) {
      copy(text);
      ElMessage.success("复制成功");
    }
    async function messageFeedback(chat, rating) {
      const {
        result
      } = await apis.rebot.messageFeedback(chat.id, {
        rating,
        user: store.state.$rebot.userInfo.userId
      });
      console.error(chat);
      if (result === 'success') {
        chat.feedback.rating = rating;
      }
    }
    return (_ctx, _cache) => {
      const _component_ChatLoading = _resolveComponent("ChatLoading");
      const _component_CircleCheck = _resolveComponent("CircleCheck");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_Close = _resolveComponent("Close");
      const _component_el_check_tag = _resolveComponent("el-check-tag");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_popover = _resolveComponent("el-popover");
      const _component_Refresh = _resolveComponent("Refresh");
      return _openBlock(), _createElementBlock("div", {
        class: "h-[calc(100%)] chat-content",
        ref_key: "scrollRef",
        ref: scrollRef,
        onScroll: handleScroll
      }, [globalParameters.value.opening_statement || globalParameters.value.suggested_questions?.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "w-[36px] h-[36px] rounded-full overflow-hidden"
      }, [_createElementVNode("div", {
        class: "w-full h-full flex items-center justify-center text-[14px]"
      }, [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      })])], -1)), _createElementVNode("div", _hoisted_2, [_createTextVNode(_toDisplayString(globalParameters.value.opening_statement) + " ", 1), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(globalParameters.value.suggested_questions, (suggest, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "bg-white text-[var(--el-color-primary)] shadow rounded-md font-bold px-[16px] py-[4px] cursor-pointer whitespace-pre-wrap",
          key: index,
          onClick: $event => sendUserMessage(suggest)
        }, _toDisplayString(suggest), 9, _hoisted_4);
      }), 128))])])])) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chats.value, (chat, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: _normalizeClass([[chat.dir === 'left' ? 'left-chat-content' : 'right-chat-content'], "w-full"]),
          key: index
        }, [_createElementVNode("div", _hoisted_5, [chat.dir === 'left' ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[1] || (_cache[1] = [_createElementVNode("img", {
          class: "w-full h-full",
          src: _imports_0,
          alt: ""
        }, null, -1)]))) : _createCommentVNode("", true), chat.dir === 'right' ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          class: "w-full h-full",
          src: userAvatar.value,
          alt: ""
        }, null, 8, _hoisted_7)) : _createCommentVNode("", true)]), chat.dir === 'left' ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [chat.loading || !(chat.content || chat.workflows.length) ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createVNode(_component_ChatLoading)])) : _createCommentVNode("", true), chat.workflows?.length ? (_openBlock(), _createElementBlock("div", _hoisted_11, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chat.workflows, workflow => {
          return _openBlock(), _createElementBlock("span", {
            class: "flex items-center mr-2",
            key: workflow.node_id
          }, [workflow.status === 'running' ? (_openBlock(), _createElementBlock("img", _hoisted_12)) : _createCommentVNode("", true), workflow.status === 'success' ? (_openBlock(), _createBlock(_component_el_icon, {
            key: 1,
            class: "mr-1",
            color: "#3F63FF"
          }, {
            default: _withCtx(() => [_createVNode(_component_CircleCheck)]),
            _: 1
          })) : _createCommentVNode("", true), _createElementVNode("span", _hoisted_13, _toDisplayString(workflow.node_title), 1)]);
        }), 128))])) : _createCommentVNode("", true), _createElementVNode("div", {
          class: "markdown-body",
          onClick: imagePreview,
          innerHTML: _unref(md).render(chat.content)
        }, null, 8, _hoisted_14), chat.id ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass([[chat.feedback?.rating || chat.showFeedbackPopup ? 'opacity-100' : 'opacity-0'], "absolute right-[15px] top-[-15px] w-[100px] h-[25px] flex justify-end gap-[10px] opacity-0 group-hover:opacity-100"])
        }, [_createElementVNode("div", {
          class: "h-[25px] w-[25px] shadow bg-white rounded-md cursor-pointer flex items-center justify-center",
          onClick: $event => copyText(chat.content)
        }, _cache[2] || (_cache[2] = [_createElementVNode("img", {
          class: "w-[15px] h-[15px]",
          src: _imports_2,
          alt: ""
        }, null, -1)]), 8, _hoisted_15), _createElementVNode("div", _hoisted_16, [chat.feedback?.rating === 'like' ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          onClick: $event => messageFeedback(chat, null),
          class: "w-[15px] h-[15px]",
          src: _imports_3,
          alt: ""
        }, null, 8, _hoisted_17)) : (_openBlock(), _createElementBlock("img", {
          key: 1,
          onClick: $event => messageFeedback(chat, 'like'),
          class: "w-[15px] h-[15px]",
          src: _imports_4,
          alt: ""
        }, null, 8, _hoisted_18)), chat.feedback?.rating === 'dislike' ? (_openBlock(), _createElementBlock("img", {
          key: 2,
          onClick: $event => messageFeedback(chat, null),
          class: "w-[15px] h-[15px]",
          src: _imports_5,
          alt: ""
        }, null, 8, _hoisted_19)) : (_openBlock(), _createBlock(_component_el_popover, {
          key: 3,
          visible: chat.showFeedbackPopup,
          placement: "bottom-end",
          width: 260,
          trigger: "click"
        }, {
          reference: _withCtx(() => [_createElementVNode("img", {
            class: "w-[15px] h-[15px]",
            onClick: $event => {
              chat.showFeedbackPopup = true;
              checkedFeedbackOptions.value = [];
            },
            src: _imports_6,
            alt: ""
          }, null, 8, _hoisted_23)]),
          default: _withCtx(() => [_createElementVNode("div", _hoisted_20, [_createVNode(_component_el_icon, {
            onClick: $event => chat.showFeedbackPopup = false,
            class: "!absolute right-1 top-1 !text-[20px] cursor-pointer"
          }, {
            default: _withCtx(() => [_createVNode(_component_Close)]),
            _: 2
          }, 1032, ["onClick"]), _cache[5] || (_cache[5] = _createElementVNode("div", {
            class: "font-bold text-[14px]"
          }, "反馈问题", -1)), _createElementVNode("div", _hoisted_21, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(feedbackOptions.value, item => {
            return _openBlock(), _createBlock(_component_el_check_tag, {
              onClick: $event => selectFeedbackOption(item),
              class: "!bg-white col-span-1 flex items-center justify-center border py-[6px] rounded-md cursor-pointer text-center",
              checked: checkedFeedbackOptions.value.includes(item.option_text),
              key: item.id
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(item.option_text), 1)]),
              _: 2
            }, 1032, ["onClick", "checked"]);
          }), 128))]), _createElementVNode("div", _hoisted_22, [_createVNode(_component_el_button, {
            class: "w-[50%]",
            onClick: $event => cancelFeedback(chat)
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("取消")])),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            class: "w-[50%] !bg-[var(--el-color-primary)]",
            type: "primary",
            disabled: !checkedFeedbackOptions.value.length,
            onClick: $event => submitFeedback(chat, index)
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("提交")])),
            _: 2
          }, 1032, ["disabled", "onClick"])])])]),
          _: 2
        }, 1032, ["visible"]))])], 2)) : _createCommentVNode("", true), chat.resources?.length ? (_openBlock(), _createElementBlock("div", _hoisted_24, [_cache[6] || (_cache[6] = _createElementVNode("div", {
          class: "font-bold text-[12px]"
        }, "来源", -1)), _createElementVNode("div", _hoisted_25, [coachConfig.value.docType === 1 ? (_openBlock(true), _createElementBlock(_Fragment, {
          key: 0
        }, _renderList(chat.resources, (resource, index) => {
          return _openBlock(), _createElementBlock("div", {
            key: index,
            onClick: $event => toPdfDetail(resource),
            class: "flex items-center bg-[#F3F4FC] py-[1px] pl-[6px] pr-[8px] rounded-md cursor-pointer text-[12px]"
          }, _toDisplayString(resource.document_name), 9, _hoisted_26);
        }), 128)) : (_openBlock(true), _createElementBlock(_Fragment, {
          key: 1
        }, _renderList(chat.resources, (resource, index) => {
          return _openBlock(), _createBlock(_component_el_popover, {
            key: index,
            placement: "top",
            width: 400,
            trigger: "click"
          }, {
            reference: _withCtx(() => [_createElementVNode("div", _hoisted_30, _toDisplayString(resource?.document_name), 1)]),
            default: _withCtx(() => [_createElementVNode("div", _hoisted_27, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(resource.contents, (content, cindex) => {
              return _openBlock(), _createElementBlock("div", {
                class: "mt-2",
                key: cindex
              }, [_createElementVNode("div", _hoisted_28, "#" + _toDisplayString(content.segment_position), 1), _createElementVNode("div", _hoisted_29, _toDisplayString(content.content), 1)]);
            }), 128))])]),
            _: 2
          }, 1024);
        }), 128))])])) : _createCommentVNode("", true)]), index === chats.value.length - 1 && !_unref(isGenerating) ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 0
        }, [_createElementVNode("div", {
          onClick: reCreateAnswer,
          class: "w-[fit-content] pl-[10px] pt-2 h-[20px] flex items-center text-[#4178FF] cursor-pointer"
        }, [_createVNode(_component_el_icon, {
          class: "mr-[6px]"
        }, {
          default: _withCtx(() => [_createVNode(_component_Refresh)]),
          _: 1
        }), _cache[7] || (_cache[7] = _createTextVNode("重新生成"))]), _createElementVNode("div", _hoisted_31, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(nextSuggested.value, (item, iidex) => {
          return _openBlock(), _createElementBlock("div", {
            key: iidex,
            onClick: $event => reEdit(item),
            class: "shadow border px-[10px] py-[4px] flex items-center bg-white rounded-md w-[fit-content] cursor-pointer"
          }, _toDisplayString(item), 9, _hoisted_32);
        }), 128))])], 64)) : _createCommentVNode("", true)])) : _createCommentVNode("", true), chat.dir === 'right' ? (_openBlock(), _createElementBlock("div", _hoisted_33, [_createElementVNode("div", _hoisted_34, [_createElementVNode("span", _hoisted_35, _toDisplayString(chat.content), 1), _createElementVNode("span", {
          class: "px-[10px] py-[8px] text-right cursor-pointer flex items-center gap-1 text-[12px]",
          onClick: $event => reEdit(chat.content)
        }, _cache[8] || (_cache[8] = [_createElementVNode("img", {
          class: "ml-auto w-[12px] h-[10px]",
          src: _imports_7,
          alt: ""
        }, null, -1), _createTextVNode(" 重新编辑 ")]), 8, _hoisted_36)])])) : _createCommentVNode("", true)], 2);
      }), 128))], 544);
    };
  }
};