import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "w-[320px] p-[20px] flex flex-col gap-[20px]"
};
const _hoisted_2 = {
  class: "flex items-center justify-center"
};
const _hoisted_3 = {
  class: "w-[66px] h-[66px] rounded-full overflow-hidden"
};
const _hoisted_4 = ["src"];
import { computed, ref } from "vue";
import store from '@/x/store';
import { useRouter } from "vue-router";
export default {
  __name: 'ProfileDialog',
  setup(__props, {
    expose: __expose
  }) {
    const showPopup = ref(false);
    const router = useRouter();
    const coachConfig = computed(() => store.state.$rebot.coachConfig);
    const userInfo = computed(() => store.state.$rebot.userInfo);
    console.error(userInfo, 'userInfo');
    const avatar = computed(() => store.state.$rebot.userInfo.headPicUrl || require('@/assets/rebot/default_avatar.jpeg'));
    __expose({
      show: () => {
        showPopup.value = true;
      }
    });
    function confirmLogout() {
      store.commit('$rebot/setUserInfo', {});
      router.push({
        name: 'login'
      });
    }
    return (_ctx, _cache) => {
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createBlock(_component_van_popup, {
        show: showPopup.value,
        "onUpdate:show": _cache[2] || (_cache[2] = $event => showPopup.value = $event),
        closeable: "",
        class: "rounded-[20px]"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
          src: avatar.value,
          alt: "",
          class: "w-full h-full"
        }, null, 8, _hoisted_4)])]), _createVNode(_component_van_field, {
          label: "用户名",
          modelValue: userInfo.value.userName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => userInfo.value.userName = $event),
          placeholder: "",
          "input-align": "right",
          disabled: "",
          class: "my-input !bg-[#f7f7f7] h-[50Px] !leading-[30Px] rounded-md after:!border-b-[0px]"
        }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
          label: "手机号",
          modelValue: userInfo.value.phoneNum,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => userInfo.value.phoneNum = $event),
          placeholder: "",
          "input-align": "right",
          disabled: "",
          class: "my-input !bg-[#f7f7f7] h-[50Px] !leading-[30Px] rounded-md after:!border-b-[0px]"
        }, null, 8, ["modelValue"]), coachConfig.value.needLogin !== 2 ? (_openBlock(), _createBlock(_component_van_button, {
          key: 0,
          onClick: confirmLogout,
          type: "default",
          class: "!rounded-lg !mt-[10px] !font-bold",
          block: ""
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("span", {
            class: "relative top-[2px]"
          }, "退出", -1)])),
          _: 1
        })) : _createCommentVNode("", true)])]),
        _: 1
      }, 8, ["show"]);
    };
  }
};