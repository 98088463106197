import { createApp } from 'vue'
import App from './App.vue'
import store from '@/x/store'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import DebugPlugin from '@/x/plugins/debug'
import ComponentsPlugin from '@/x/plugins/components'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import watermark from '@/libs/watermark'

// const app = createApp(App)
// for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
//   app.component(key, component)
// }
// vant 使用api 需要另外引入样式
// Toast
import "vant/es/toast/style";
// Dialog
import "vant/es/dialog/style";
// Notify
import "vant/es/notify/style";
// ImagePreview
import "vant/es/image-preview/style";

// app.use(ComponentsPlugin)
// app.use(store)
// app.use(router)
// app.use(ElementPlus)
// app.use(DebugPlugin)
// app.use(watermark)
// app.mount('#app')

if (window.__POWERED_BY_QIANKUN__) {
  console.log("??",window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__)
  window.__webpack_public_path__ = window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__ + '/rebot'
}

let instance = null;

function render(props = {}) {
  const {container} = props
  instance = createApp(App)
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    instance.component(key, component)
  }
  instance
      .use(ComponentsPlugin)
      .use(store)
      .use(router)
      .use(ElementPlus)
      .use(DebugPlugin)
      .use(watermark)
      .mount(container ? container.querySelector('#app') : '#app')

  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
      instance.component(key, component)
  }
  console.log(2234, instance)
  instance.config.globalProperties.parentActions = props.parentActions
}


// 独立运行时
if (!window.__POWERED_BY_QIANKUN__) {
  render();
}


// 子应用挂载前的钩子
export async function bootstrap() {
  console.log('[vue] vue app bootstraped');
}

// 子应用挂载时的钩子
export async function mount(props) {
  console.log('[vue] props from main framework', props);
  render(props);
}

// 子应用销毁时的钩子
export async function unmount() {
  console.log(instance)
  instance.unmount();
  // instance.$el.innerHTML = '';
  // instance = null;
}