import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "w-full h-[calc(100%-140px)] px-[20px] pb-[5px] overflow-auto"
};
const _hoisted_2 = {
  class: "pt-[60px] px-[20px]"
};
const _hoisted_3 = {
  class: "text-[26px] font-bold text-center"
};
const _hoisted_4 = {
  class: "text-[15px] text-[#666] max-w-[700px] text-left m-auto mt-[10px] indent-[2em]"
};
const _hoisted_5 = {
  class: "flex flex-wrap max-w-[1000px] m-auto px-[20px] gap-[20px] mt-[30px]"
};
const _hoisted_6 = {
  class: "h-full"
};
const _hoisted_7 = {
  class: "absolute bottom-[20px] w-[calc(100%-40px)]"
};
import { ref, computed, onBeforeMount, nextTick, watch, provide } from "vue";
import store from "@/x/store";
export default {
  __name: 'MainContent',
  emits: ['history'],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const emits = __emit;
    const coachConfig = computed(() => store.state.$rebot.coachConfig);
    const currentConversation = computed(() => store.state.$rebot.currentConversation);
    const isDefault = computed(() => {
      return !currentConversation.value || !currentConversation.value?.id;
    });
    watch(() => currentConversation.value, (newVal, oldVal) => {
      console.error('currentConversation', newVal, oldVal);
      // store.dispatch('$rebot/getHistoryMessages')
      nextTick(() => {
        reEditMessage('');
      });
      console.error(oldVal, newVal);
      if (newVal && oldVal && !(newVal.id === 'default' && oldVal.id === '')) {
        stopGenerating();
      }
    });
    const loginDialog = ref(null);
    function userLogin() {
      loginDialog.value.show();
    }
    const isGenerating = ref(false);
    provide('isGenerating', isGenerating);
    const chat = ref(null);
    function stopGenerating() {
      nextTick(() => {
        chat.value.stopGenerating();
      });
    }
    function sendUserMessage(msg) {
      if (isDefault.value) {
        startNewChat();
        store.commit("$rebot/setCurrentConversation", {
          id: 'default'
        });
        nextTick(() => {
          chat.value.sendUserMessage(msg);
        });
        return false;
      }
      chat.value.sendUserMessage(msg);
    }
    function startNewChat() {
      store.dispatch('$rebot/startNewChat');
    }
    const input = ref(null);
    function reEditMessage(msg) {
      input.value.setMessage(msg);
    }
    onBeforeMount(() => {
      store.dispatch('$rebot/getFeedbackOptions');
    });
    __expose({
      reEditMessage
    });
    return (_ctx, _cache) => {
      const _component_Recommend = _resolveComponent("Recommend");
      const _component_History = _resolveComponent("History");
      const _component_ChatContent = _resolveComponent("ChatContent");
      const _component_UserInput = _resolveComponent("UserInput");
      const _component_LoginDialog = _resolveComponent("LoginDialog");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [isDefault.value ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(coachConfig.value.appName), 1), _createElementVNode("div", _hoisted_4, _toDisplayString(coachConfig.value.describe), 1)]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_Recommend, {
        onSend: sendUserMessage
      }), coachConfig.value.showHistory === 1 ? (_openBlock(), _createBlock(_component_History, {
        key: 0,
        onSend: sendUserMessage,
        onHistory: _cache[0] || (_cache[0] = $event => emits("history"))
      })) : _createCommentVNode("", true)])], 64)) : _createCommentVNode("", true), _withDirectives(_createElementVNode("div", _hoisted_6, [_createVNode(_component_ChatContent, {
        ref_key: "chat",
        ref: chat,
        onReEdit: reEditMessage
      }, null, 512)], 512), [[_vShow, !isDefault.value]]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_UserInput, {
        ref_key: "input",
        ref: input,
        onLogin: userLogin,
        onSend: sendUserMessage,
        onStop: stopGenerating
      }, null, 512)])]), _createVNode(_component_LoginDialog, {
        ref_key: "loginDialog",
        ref: loginDialog
      }, null, 512)], 64);
    };
  }
};