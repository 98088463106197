const Base64 = {
  encode(str) {
      return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
          function toSolidBytes(match, p1) {
              return String.fromCharCode(Number('0x' + p1));
          }));
  },
  decode(str) {
      return decodeURIComponent(atob(str).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
  }
}
export default Base64;
// let encoded = Base64.encode("一颗不甘坠落的流星"); 	// "5LiA6aKX5LiN55SY5Z2g6JC955qE5rWB5pif"
// let decoded = Base64.decode(encoded); 				// "一颗不甘坠落的流星"