// import { v4 as uuidv4 } from "uuid";
import {generateuuid} from "@/x/tools";
// import Cookies from 'js-cookie'
import axios from 'axios'

export default class SpeechRecognizer {
  constructor() {
    // this.appid = params.appid || ''
    // this.secretid = params.secretid || ''
    this.message_id = '';
    this.websocket_task_id = '';
    this.socket = null;
    this.isSignSuccess = false;
    this.isSentenceBegin = false; // 是否一句话开始
    this.isRecognizeComplete = false; // 当前是否识别结束
  }
  // 暂停识别，关闭连接
  stop() {
    if (this.socket && this.socket.readyState === 1) {
      this.socket.send(JSON.stringify(
        {
          "header": {
            "message_id": this.message_id,
            "task_id": this.websocket_task_id,
            "namespace": "SpeechTranscriber",
            "name": "StopTranscription",
            "appkey": process.env.VUE_APP_ALI_APP_KEY
          }
        }
      ));
    } else {
      this.OnError('连接未建立或连接已关闭');
      if (this.socket && this.socket.readyState === 1) {
        this.socket.close();
      }
    }
  }
  getCookie(name) {
    // 拆分 cookie 字符串
    var cookieArr = document.cookie.split(";");

    // 循环遍历数组元素
    for(var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");

      /* 删除 cookie 名称开头的空白并将其与给定字符串进行比较 */
      if(name == cookiePair[0].trim()) {
        // 解码cookie值并返回
        return decodeURIComponent(cookiePair[1]);
      }
    }
    // 如果未找到，则返回null
    return null;
  }
  async getToken() {
    //'c02dab7b5d574430bf006431a6dbd179'
    console.log('123321token',process.env.VUE_APP_ALI_APP_KEY,process.env.VUE_APP_BASE_API,process.env,'/' + process.env.VUE_APP_BASE_API+ '/aliyunSpeech/getToken')
    return await axios({
      method: 'get',
      url: process.env.VUE_APP_BASE_API+ '/aliyunSpeech/getToken',
      headers: {'Authorization': 'Bearer ' + this.getCookie('Admin-Token')}
    }).then((res) => {
      console.log(res)
      return res.data.data.token
    })
    // return 'c02dab7b5d574430bf006431a6dbd179'
  }
  // 拼接鉴权数据
  async getUrl() {
    const token = await this.getToken()
    return `nls-gateway.cn-shanghai.aliyuncs.com/ws/v1?token=${token}`;
  }
  // 建立websocket链接 data 为用户收集的音频数据
  async start() {
    const url = await this.getUrl();
    if ('WebSocket' in window) {
      this.socket = new WebSocket(`wss://${url}`);
    } else if ('MozWebSocket' in window) {
      this.socket = new window.MozWebSocket(`wss://${url}`);
    } else {
      this.OnError('浏览器不支持WebSocket');
      return
    }
    this.socket.onopen = (e) => { // 连接建立时触发
      console.error("socket open:", e)
      this.websocket_task_id = generateuuid();
      //生成ali的请求参数message_id
      this.message_id = generateuuid();
      let actions = {
        "header":{
          "namespace":"SpeechTranscriber",    //固定值
          "name": "StartTranscription",       //发送请求的名称，固定值
          "appkey": process.env.VUE_APP_ALI_APP_KEY, //appkey
          "message_id": this.message_id,           //消息id
          "task_id": this.websocket_task_id,       //任务id
        },
        "payload":{
          "format": "PCM",//音频编码格式，默认是PCM（无压缩的PCM文件或WAV文件），16bit采样位数的单声道。
          "sample_rate": 16000, //需要与录音采样率一致、默认是16000，单位是Hz。
          "enable_intermediate_result": true, //是否返回中间识别结果，默认是false。
          "enable_punctuation_prediction": true, //是否在后处理中添加标点，默认是false。
          "enable_inverse_text_normalization": true, //是否在后处理中执行数字转写，默认是false。
          "max_sentence_silence": 500,//	语音断句检测阈值，静音时长超过该阈值会被认为断句，参数范围200ms～2000ms，默认值800ms。
        }
      }
      this.socket.send(JSON.stringify(actions));
    };
    this.socket.onmessage = (e) => {
      //接受ali 语音返回的数据
      const ret = JSON.parse(e.data);
      console.error("socket message:", ret)
      //判断返回的数据类型
      if (ret.header.name === 'TranscriptionResultChanged'){
        //数据在收集中 一句话的中间结果
        console.log('数据在收集中');
        //实时获取语音转文本的结果
        this.OnRecognitionResultChange(ret.payload.result);
      } else if (ret.header.name === 'SentenceBegin') {
        //一句话开始后，就可以启动录音了
        console.log('检测到了一句话的开始');
        this.OnSentenceBegin(ret);
      } else if (ret.header.name === 'TranscriptionStarted') {
        console.log("服务端已经准备好了进行识别，客户端可以发送音频数据了");
        //获取音频信息，定时获取并发送
        if (!this.isSignSuccess) {
          this.OnRecognitionStart(ret);
          this.isSignSuccess = true;
        }
      } else if (ret.header.name === 'SentenceEnd') {
        console.log('数据接收结束', ret);
        this.OnSentenceEnd(ret.payload.result);
      } else if (ret.header.name === 'TranscriptionCompleted') {
        console.log('服务端已停止了语音转写', ret);
        this.isRecognizeComplete = true;
        this.OnRecognitionComplete(ret);
      } else if (ret.header.name === 'TaskFailed') {
        console.log('服务端识别失败', ret);
        this.isRecognizeComplete = true;
        this.OnError(ret);
      }
    };
    this.socket.onerror = (e) => { // 通信发生错误时触发
      this.socket.close();
      this.OnError(e);
    }
    this.socket.onclose = (event) => {
      if (!this.isRecognizeComplete) {
        this.OnError(event);
      }
      this.isSignSuccess = false
    }
  }
  // 发送数据
  write(data) {
    if (!this.socket || this.socket.readyState !== 1) {
      this.OnError('连接未建立，请稍后发送数据！')
      return
    }
    this.socket.send(data);
  }

  // 开始识别的时候
  OnRecognitionStart() {

  }
  // 一句话开始的时候
  OnSentenceBegin() {

  }
  // 识别结果发生变化的时候
  OnRecognitionResultChange() {

  }
  // 一句话结束的时候
  OnSentenceEnd() {

  }
  // 识别结束的时候
  OnRecognitionComplete() {

  }
  // 识别失败
  OnError() {

  }
}
window && (window.SpeechRecognizer = SpeechRecognizer);
