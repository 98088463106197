const audioWorkletCode = `
class MyProcessor extends AudioWorkletProcessor {
  constructor(options) {
    super(options);
    this.audioData = [];
    this.nextUpdateFrame = 40;
  }

  get intervalInFrames() {
    return 200 / 1000 * sampleRate;
  }

  process(inputs) {
    // 去处理音频数据
    // eslint-disable-next-line no-undef
    if (inputs[0][0]) {
      const output = ${to16kHz}(inputs[0][0], sampleRate);
      const audioData = ${to16BitPCM}(output);
      const data = [...new Int8Array(audioData.buffer)];
      this.audioData = this.audioData.concat(data);
      this.nextUpdateFrame -= inputs[0][0].length;
      if (this.nextUpdateFrame < 0) {
        this.nextUpdateFrame += this.intervalInFrames;
        this.port.postMessage({
          audioData: new Int8Array(this.audioData)
        });
        this.audioData = [];
      }
        return true;
      }
  }
}

registerProcessor('my-processor', MyProcessor);
`;
const audioWorkletBlobURL = window.URL.createObjectURL(new Blob([audioWorkletCode], { type: 'text/javascript' }));

function to16BitPCM(input) {
  const dataLength = input.length * (16 / 8);
  const dataBuffer = new ArrayBuffer(dataLength);
  const dataView = new DataView(dataBuffer);
  let offset = 0;
  for (let i = 0; i < input.length; i++, offset += 2) {
    const s = Math.max(-1, Math.min(1, input[i]));
    dataView.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }
  return dataView;
}
function to16kHz(audioData, sampleRate= 44100) {
  const data = new Float32Array(audioData);
  const fitCount = Math.round(data.length * (16000 / sampleRate));
  const newData = new Float32Array(fitCount);
  const springFactor = (data.length - 1) / (fitCount - 1);
  newData[0] = data[0];
  for (let i = 1; i < fitCount - 1; i++) {
    const tmp = i * springFactor;
    const before = Math.floor(tmp).toFixed();
    const after = Math.ceil(tmp).toFixed();
    const atPoint = tmp - before;
    newData[i] = data[before] + (data[after] - data[before]) * atPoint;
  }
  newData[fitCount - 1] = data[data.length - 1];
  return newData;
}

export default class WebRecorder {
  constructor(options = {}) {
    this.enableWorklet = !(options.enableWorklet === false)
    this.sampleRate = options.sampleRate || 8000
    if(![0, 256, 512, 1024, 2048, 4096, 8192, 16384].includes(options.bufferSize)) {
      this.OnError('bufferSize需为[0, 256, 512, 1024, 2048, 4096, 8192, 16384]中的一个');
    }
    this.bufferSize = [0, 256, 512, 1024, 2048, 4096, 8192, 16384].includes(options.bufferSize) ? options.bufferSize : 0;
    this.audioData = [];
    this.stream = null;
    this.audioContext = null;
    if (!WebRecorder.instance) {
      WebRecorder.instance = this;
    }
  }
  start() {
    if (this.audioContext) {
      this.OnError('录音已开启');
      return;
    }

    navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia
      || navigator.mozGetUserMedia || navigator.msGetUserMedia;

    try {
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      this.audioContext.resume();
      if (!this.audioContext) {
        this.OnError('浏览器不支持webAudioApi相关接口');
        return;
      }
    } catch (e) {
      if (!this.audioContext) {
        this.OnError('浏览器不支持webAudioApi相关接口');
        return;
      }
    }

    // 获取用户的麦克风
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
          video: false,
        })
        .then(stream => {
            getAudioSuccess(stream);
        })
        .catch(e => {
          getAudioFail(e);
        });
    } else if (navigator.getUserMedia) {
      navigator.getUserMedia(
        {
          audio: true,
          video: false,
        },
        stream => {
          getAudioSuccess(stream);
        },
        function(e) {
          getAudioFail(e);
        }
      );
    } else {
      if (navigator.userAgent.toLowerCase().match(/chrome/) && location.origin.indexOf('https://') < 0) {
        this.OnError('chrome下获取浏览器录音功能，因为安全性问题，需要在localhost或127.0.0.1或https下才能获取权限');
      } else {
        this.OnError('无法获取浏览器录音功能，请升级浏览器或使用chrome');
      }
      this.audioContext && this.audioContext.close();
      return;
    }
    const getAudioSuccess = (stream) => {
      this.stream = stream;
      const mediaStreamSource = this.audioContext.createMediaStreamSource(this.stream); // 将声音对象输入这个对象
      if (this.audioContext.audioWorklet && (this.enableWorklet = false)) {
        this.audioContext.audioWorklet.addModule(audioWorkletBlobURL).then(() => {
          const myNode = new AudioWorkletNode(this.audioContext, 'my-processor', { numberOfInputs: 1, numberOfOutputs: 1, channelCount: 1 });
          myNode.port.onmessage = (event) => {
            const waveData = this.parseToWaveData(event.data.audioData)
            this.OnReceivedData(event.data.audioData, waveData)
          };
          mediaStreamSource.connect(myNode).connect(this.audioContext.destination);
        }).catch(console.error);
      } else {
        // 创建一个音频分析对象，采样的缓冲区大小为0（自动适配），输入和输出都是单声道
        const scriptProcessor = this.audioContext.createScriptProcessor(0, 1, 1);
        scriptProcessor.onaudioprocess = (e) => {
          // 去处理音频数据
          const inputData = e.inputBuffer.getChannelData(0);
          const output = to16kHz(inputData, this.audioContext.sampleRate);
          const audioData = to16BitPCM(output);
          this.audioData.push(...new Int8Array(audioData.buffer));
          if (this.audioData.length > 6400) {
            const audioDataArray = new Int8Array(this.audioData);
            const waveData = this.parseToWaveData(audioDataArray)
            this.OnReceivedData(audioDataArray, waveData);
            this.audioData = [];
          }
        };
        // 连接
        mediaStreamSource.connect(scriptProcessor);
        scriptProcessor.connect(this.audioContext.destination);
      }
    };
    const getAudioFail = (err) => {
      this.OnError(err);
      this.stop();
    };
  }
  parseToWaveData(audioData) {
    var int16Array = new Int16Array(audioData.length * 2);
    for (var i = 0, j = 0; i < audioData.length; i++, j += 2) {
        // 将Int8Array的每个元素放大为2倍，并赋值给Int16Array
        int16Array[j / 2] = audioData[i] << 4; // 左移8位相当于乘以256
    }
    return int16Array
  }
  encodeWaveData(audioData){
    const size = audioData.length;
    const pcm = new Int16Array(size);
    for(let j=0; j<size; j++){
      let s=Math.max(-1,Math.min(1,audioData[j]));
      s = s<0 ? s*0x8000 : s*0x7FFF;
      pcm[j]=s;
    }
    return pcm
  }
  stop() {
    // if (!(/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent))){
    //   this.audioContext && this.audioContext.suspend();
    // }
    console.error("recorder stop...")
    this.audioContext && this.audioContext.suspend();
    // 关闭通道
    if (this.stream) {
      this.stream.getTracks().map((val) => {
        val.stop();
      });
      this.stream = null;
    }
  }
  // function detectSilence(
  //   stream,
  //   silence_delay = 500,
  //   min_decibels = -80
  // ) {
  //   const ctx = new AudioContext();
  //   const analyser = ctx.createAnalyser();
  //   const streamNode = ctx.createMediaStreamSource(stream);
  //   streamNode.connect(analyser);
  //   analyser.minDecibels = min_decibels;
  
  //   const data = new Uint8Array(analyser.frequencyBinCount); // will hold our data
  //   let silence_start = performance.now();
  //   let triggered = false; // trigger only once per silence event
  
  //   function loop(time) {
  //     requestAnimationFrame(loop); // we'll loop every 60th of a second to check
  //     analyser.getByteFrequencyData(data); // get current data
  //     if (data.some(v => v)) { // if there is data above the given db limit
  //       if(triggered){
  //         triggered = false;
  //         onSoundStart();
  //         }
  //       silence_start = time; // set it to now
  //     }
  //     if (!triggered && time - silence_start > silence_delay) {
  //       onSoundEnd();
  //       triggered = true;
  //     }
  //   }
  //   loop();
  // }
  OnReceivedData() { // 获取音频数据

  }
  OnError() {

  }
}
window && (window.WebRecorder = WebRecorder);