
const path = require('path')
export default {
  install: (app) => {
    const files = require.context('@rebot/components', false, /\.vue$/)
    console.error(files.keys())
    files.keys().forEach((key) => {
      const name = path.basename(key, ".vue")
      app.component(name, files(key).default || files(key))
    })
  }
}