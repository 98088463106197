import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "py-3"
};
import { onBeforeMount, ref, watchEffect } from "vue";
// import projectInfo from "@/libs/data/project_info"
// import projectApplication from "@/libs/data/project_application"
import apis from "@/x/server";
export default {
  __name: 'RightSideBarContent',
  emits: ['reEdit'],
  setup(__props, {
    emit: __emit
  }) {
    const selectProject = ref('');
    const options = ref([]);
    onBeforeMount(() => {
      getTreeData();
    });
    async function getTreeData() {
      const data = await apis.rebot.getTreeData();
      options.value = data;
    }
    const emits = __emit;
    function handleNodeClick(node) {
      if (node.type === 'query') {
        emits('reEdit', node.name);
      }
    }
    const treeData = ref([]);
    watchEffect(() => {
      treeData.value = options.value.find(item => item.id === selectProject.value)?.children || [];
    });
    const defaultProps = {
      children: 'children',
      label: 'name'
    };
    return (_ctx, _cache) => {
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_tree = _resolveComponent("el-tree");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "text-[18px] font-bold my-2"
      }, "数据", -1)), _createVNode(_component_el_select, {
        modelValue: selectProject.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => selectProject.value = $event),
        placeholder: "请选择",
        size: "large",
        style: {
          "width": "100%"
        },
        "popper-append-to-body": false
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(options.value, item => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: item.id,
            label: item.name,
            value: item.id
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"]), _cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "text-[18px] font-bold my-2"
      }, "维度", -1)), _createVNode(_component_el_tree, {
        class: "my-tree",
        style: {
          "max-width": "600px"
        },
        data: treeData.value,
        props: defaultProps,
        "empty-text": "暂无数据",
        onNodeClick: handleNodeClick
      }, null, 8, ["data"])]);
    };
  }
};