import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "flex items-center justify-between h-full px-[15px] text-[20px] border-b"
};
const _hoisted_2 = {
  class: "flex-1 relative h-full overflow-hidden"
};
import { watermark } from "watermark-dom";
import { onBeforeMount, ref, computed, watchEffect } from "vue";
import store from "@/x/store";
import { useRoute, useRouter } from "vue-router";
import { v4 as uuidv4 } from "uuid";
import { isComWx } from "@/x/tools/reg";
export default {
  __name: 'index',
  setup(__props) {
    const router = useRouter();
    const showSideBar = ref(false);
    const route = useRoute();
    function handlerShowSideBar() {
      showSideBar.value = true;
    }
    const showRightSideBar = ref(false);
    const coachConfig = computed(() => store.state.$rebot.coachConfig);
    const watermarkText = computed(() => {
      if (store.state.$rebot.userInfo.userName && store.state.$rebot.userInfo.phoneNum) {
        return `${store.state.$rebot.userInfo.userName}\n${store.state.$rebot.userInfo.phoneNum.slice(-4)}`;
      }
      if (store.state.$rebot.userInfo.userName) {
        return `${store.state.$rebot.userInfo.userName}`;
      }
      if (store.state.$rebot.userInfo.phoneNum) {
        return `${store.state.$rebot.userInfo.phoneNum.slice(-4)}`;
      }
      return ``;
    });
    watchEffect(() => {
      if (coachConfig.value.needWatermark && watermarkText.value) {
        watermark.load({
          watermark_txt: watermarkText.value,
          watermark_color: 'gray',
          watermark_fontsize: '16px'
        });
      }
    });
    onBeforeMount(async () => {
      store.commit('$rebot/setRebotVersion', 1);
      const {
        appId = '2'
      } = route.query;
      store.commit("$rebot/setAppId", appId);
      await store.dispatch('$rebot/getCoachConfig', {
        appId
      });
      if (coachConfig.value.needLogin === 1) {
        if (!store.state.$rebot.userInfo.userId || !store.state.$rebot.userInfo.token) {
          router.push({
            name: 'login',
            query: {
              appId
            }
          });
          return false;
        }
      }
      if (coachConfig.value.needLogin === 2) {
        if (!store.state.$rebot.userInfo.userId || !store.state.$rebot.userInfo.token) {
          if (isComWx()) {
            router.push({
              name: 'wecomLogin',
              query: {
                backUrl: encodeURIComponent(window.location.href),
                appId
              }
            });
          } else {
            router.push({
              name: 'login',
              query: {
                appId
              }
            });
          }
          return false;
        }
      }
      if (!store.state.$rebot.userInfo.userId) {
        store.commit('$rebot/setUserInfo', {
          userId: uuidv4()
        });
      }
      if (coachConfig.value.appName) {
        document.title = coachConfig.value.appName;
      }
      initPage();
    });
    const currentConversation = computed(() => store.state.$rebot.currentConversation);
    function initPage() {
      store.dispatch("$rebot/getConversations");
      store.dispatch("$rebot/getParameters");
      if (!currentConversation.value?.id) {
        store.commit('$rebot/setCurrentConversation', null);
      } else {
        store.dispatch('$rebot/getHistoryMessages');
      }
    }
    return (_ctx, _cache) => {
      const _component_Operation = _resolveComponent("Operation");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_header = _resolveComponent("el-header");
      const _component_ShortSideBar = _resolveComponent("ShortSideBar");
      const _component_el_aside = _resolveComponent("el-aside");
      const _component_MainContent = _resolveComponent("MainContent");
      const _component_el_main = _resolveComponent("el-main");
      const _component_el_container = _resolveComponent("el-container");
      const _component_el_drawer = _resolveComponent("el-drawer");
      const _component_SideBarContent = _resolveComponent("SideBarContent");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_container, {
        class: "!h-[100%] !min-w-[300px]"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_header, {
          class: "pc:hidden !h-[50px] !p-0"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_icon, {
            onClick: handlerShowSideBar
          }, {
            default: _withCtx(() => [_createVNode(_component_Operation)]),
            _: 1
          })])]),
          _: 1
        }), _createVNode(_component_el_container, {
          class: "bg-[#F3F7FF] h-[calc(100%-50px)] pc:h-[100%]"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_aside, {
            class: "hidden pc:flex items-center justify-center h-[100%] px-4",
            width: "80px"
          }, {
            default: _withCtx(() => [_createVNode(_component_ShortSideBar, {
              onHistory: _cache[0] || (_cache[0] = $event => showRightSideBar.value = true)
            })]),
            _: 1
          }), _createVNode(_component_el_main, {
            class: "!p-0 !flex !flex-col !flex-1"
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_MainContent, {
              onHistory: _cache[1] || (_cache[1] = $event => showRightSideBar.value = true)
            })])]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_drawer, {
        modelValue: showSideBar.value,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => showSideBar.value = $event),
        direction: "ltr",
        class: "v2-drawer !w-[80px] !bg-transparent !shadow-none",
        withHeader: false
      }, {
        default: _withCtx(() => [_createVNode(_component_ShortSideBar, {
          onHide: _cache[2] || (_cache[2] = $event => showSideBar.value = false),
          onHistory: _cache[3] || (_cache[3] = $event => showRightSideBar.value = true)
        })]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_el_drawer, {
        modelValue: showRightSideBar.value,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => showRightSideBar.value = $event),
        direction: "rtl",
        class: "!w-[240px]",
        withHeader: false
      }, {
        default: _withCtx(() => [_createVNode(_component_SideBarContent, {
          onSelect: _cache[5] || (_cache[5] = $event => {
            showSideBar.value = false;
            showRightSideBar.value = false;
          })
        })]),
        _: 1
      }, 8, ["modelValue"])], 64);
    };
  }
};