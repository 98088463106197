import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "flex-1 min-w-[300px] px-[20px] py-[15px] bg-white shadow rounded-lg"
};
const _hoisted_2 = {
  class: "flex flex-col gap-[5px] text-[14px] mt-[14px] cursor-pointer"
};
import store from "@/x/store";
import { computed } from "vue";
export default {
  __name: 'History',
  emits: ['history'],
  setup(__props, {
    emit: __emit
  }) {
    const conversationList = computed(() => {
      return (store.state.$rebot.conversationList || []).slice(0, 5);
    });
    const emits = __emit;
    function viewAll() {
      emits('history');
    }
    return (_ctx, _cache) => {
      return conversationList.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: "flex items-center justify-between"
      }, [_cache[0] || (_cache[0] = _createElementVNode("span", {
        class: "font-bold text-[18px]"
      }, "历史问题", -1)), _createElementVNode("span", {
        class: "text-[#A0A0A0] text-[14px] cursor-pointer",
        onClick: viewAll
      }, "查看全部>>")]), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(conversationList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index
        }, _toDisplayString(item.name), 1);
      }), 128))])])) : _createCommentVNode("", true);
    };
  }
};