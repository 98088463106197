import { createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/rebot/ai_avatar.png';
import _imports_1 from '@/assets/rebot/create_new.png';
import _imports_2 from '@/assets/rebot/history.png';
const _hoisted_1 = {
  class: "border border-[#F3F3F3] w-[60px] opacity-85 h-[fit-content] rounded-md min-h-[100px] flex flex-col items-center gap-[15px] py-[10px] bg-white"
};
const _hoisted_2 = ["src"];
import store from "@/x/store";
import { computed, ref } from "vue";
import ProfileDialog from "./ProfileDialog.vue";
export default {
  __name: 'ShortSideBar',
  emits: ['hide', 'history'],
  setup(__props, {
    emit: __emit
  }) {
    const userInfo = computed(() => store.state.$rebot.userInfo);
    const avatar = computed(() => store.state.$rebot.userInfo?.headPicUrl || require('@/assets/rebot/default_avatar.jpeg'));
    const emits = __emit;
    function startNewChat() {
      store.dispatch('$rebot/startNewChat');
      emits("hide");
    }
    const profileDialog = ref(null);
    function showProfile() {
      if (userInfo.value.userName && userInfo.value.phoneNum) {
        profileDialog.value.show();
      }
    }
    function showHistory() {
      emits("history");
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "cursor-pointer"
      }, [_createElementVNode("img", {
        src: _imports_0,
        alt: "",
        class: "w-[38px]"
      })], -1)), _createElementVNode("div", {
        class: "cursor-pointer",
        onClick: startNewChat
      }, _cache[0] || (_cache[0] = [_createElementVNode("img", {
        src: _imports_1,
        alt: "",
        class: "w-[25px]"
      }, null, -1)])), _createElementVNode("div", {
        class: "cursor-pointer",
        onClick: showHistory
      }, _cache[1] || (_cache[1] = [_createElementVNode("img", {
        src: _imports_2,
        alt: "",
        class: "w-[25px]"
      }, null, -1)])), _createElementVNode("div", {
        class: "cursor-pointer",
        onClick: showProfile
      }, [_createElementVNode("img", {
        src: avatar.value,
        alt: "",
        class: "w-[32px] rounded-full overflow-hidden"
      }, null, 8, _hoisted_2)])]), _createVNode(ProfileDialog, {
        ref_key: "profileDialog",
        ref: profileDialog
      }, null, 512)], 64);
    };
  }
};