import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/rebot/loading.png';
const _hoisted_1 = {
  class: "flex items-center"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [_createElementVNode("img", {
    class: "w-[13px] h-[13px] mr-2 animate-rotating",
    src: _imports_0,
    alt: ""
  }, null, -1), _createElementVNode("span", {
    class: "font-bold"
  }, "查询中...", -1)]));
}