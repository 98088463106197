import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "w-[320px] h-[300px] p-[20px] flex flex-col gap-[25px]"
};
import { ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import { isPhone } from "@/x/tools/reg";
import store from "@/x/store";
export default {
  __name: 'LoginDialog',
  setup(__props, {
    expose: __expose
  }) {
    const showPopup = ref(false);
    const formData = reactive({
      phone: '',
      password: ''
    });
    const showPassword = ref(false);
    __expose({
      show: () => {
        showPopup.value = true;
      }
    });
    function confirmLogin() {
      login();
    }
    async function login() {
      if (!formData.phone) {
        ElMessage.error("请输入手机号！");
        return;
      }
      if (!isPhone(formData.phone)) {
        ElMessage.error("请输入正确的手机号！");
      }
      if (!formData.password) {
        ElMessage.error("请输入密码！");
        return;
      }
      console.error(formData.phone, formData.password);
      await store.dispatch('$rebot/coachLogin', {
        phoneNum: formData.phone,
        password: '1234',
        userName: "",
        employeeNum: ""
      });
      showPopup.value = false;
    }
    return (_ctx, _cache) => {
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createBlock(_component_van_popup, {
        show: showPopup.value,
        "onUpdate:show": _cache[4] || (_cache[4] = $event => showPopup.value = $event),
        closeable: "",
        class: "rounded-[20px]"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_cache[6] || (_cache[6] = _createElementVNode("div", {
          class: "font-bold text-center text-[15px]"
        }, "🎉 登录后开始畅聊", -1)), _createVNode(_component_van_field, {
          modelValue: formData.phone,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formData.phone = $event),
          placeholder: "输入手机号",
          class: "!bg-[#f7f7f7] h-[50Px] !leading-[30Px] rounded-full after:!border-b-[0px]"
        }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
          modelValue: formData.password,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => formData.password = $event),
          placeholder: "输入密码",
          type: showPassword.value ? 'text' : 'password',
          class: "!bg-[#f7f7f7] h-[50Px] !leading-[30Px] rounded-full after:!border-b-[0px]"
        }, {
          "right-icon": _withCtx(() => [!showPassword.value ? (_openBlock(), _createBlock(_component_van_icon, {
            key: 0,
            onClick: _cache[1] || (_cache[1] = $event => showPassword.value = true),
            name: "closed-eye"
          })) : (_openBlock(), _createBlock(_component_van_icon, {
            key: 1,
            onClick: _cache[2] || (_cache[2] = $event => showPassword.value = false),
            name: "eye-o"
          }))]),
          _: 1
        }, 8, ["modelValue", "type"]), _createVNode(_component_van_button, {
          onClick: confirmLogin,
          type: "primary",
          class: "!bg-[var(--van-primary-color)] !rounded-full !mt-[10px]",
          block: ""
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("🔑开启新宇宙")])),
          _: 1
        })])]),
        _: 1
      }, 8, ["show"]);
    };
  }
};